<app-alert #alert></app-alert>
<div
  class="forgot-password-container flex justify-content-center align-items-center pb-2"
>
  <!-- the forgot-password form -->
  <form class="forgot-password-form text-center my-5">
    <!--  the title in the forgot-password form  -->
    <div class="forgot-password-title mb-5">
      <h1>{{ "authentification.forget-password.title" | translate }}</h1>
      <div class="text-muted">
        {{ "authentification.forget-password.instruction" | translate }}
      </div>
    </div>

    <!--   input of the email   -->
    <div class="form-group" [formGroup]="forgetForm">
      <input
        name="email"
        type="text"
        class="form-control form-control-solid h-auto"
        [ngClass]="{ 'is-invalid': email.invalid && email.dirty }"
        placeholder="{{ 'authentification.email.placeholder' | translate }}"
        (keyup.enter)="sendMail()"
        formControlName="email"
      />
      <!--   feedback if the e-mail is invalid   -->
      <div
        class="invalid-feedback"
        *ngIf="email.invalid && email.errors.required"
      >
        {{ "authentification.email.feedback-empty" | translate }}
      </div>
      <div class="invalid-feedback" *ngIf="email.invalid && email.errors.email">
        {{ "authentification.email.feedback-wrong-format" | translate }}
      </div>
    </div>

    <!--   auth btn   -->
    <button
      (click)="sendMail()"
      class="btn mt-3 btn-lg btn-dark-primary text-uppercase"
    >
      {{ "submit" | translate }}
    </button>
  </form>
</div>
