import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ACTIVATE_ACCOUNT,
  CHOOSE_NEW_PASSWORD,
  CREATE_SESSION_URL,
  PASSWORD_FORGOTTEN,
  REGISTER_USER_URL,
  RESET_PASSWORD,
  LOGOUT_URL,
  PATH_LOGIN,
} from '../utils/cst';
import { Router } from '@angular/router';
import { LangService } from './lang.service';

@Injectable()
export class AuthService {
  public params: HttpParams;
  public userName: string;
  public spinner: NgxSpinnerService;
  public beforeLogOut;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private langService: LangService
  ) {}

  setSpinner(spinner: NgxSpinnerService) {
    this.spinner = spinner;
  }

  // *****************************************************************************************************************
  // REQUESTS FOR AUTHENTICATION
  // *****************************************************************************************************************

  login(log: string, pass: string, isRelogin = false) {
    return this.httpClient
      .post<any>(CREATE_SESSION_URL, { email: log, password: pass })
      .pipe(
        tap((sessionResponse) => {
          // Spring Security with JWT:
          if (sessionResponse.jwt !== null) {
            if (!isRelogin) {
              localStorage.clear();
            }
            localStorage.setItem('token', sessionResponse.jwt);
            this.langService.update(sessionResponse.lang, false);
          }
        })
      );
  }

  register(name: string, email: string, password: string, lang: string) {
    return this.httpClient.post<any>(REGISTER_USER_URL, {
      name,
      email,
      password,
      lang,
    });
  }

  passwordForgotten(email: string) {
    return this.httpClient.post<any>(PASSWORD_FORGOTTEN, email);
  }

  chooseNewPassword(passwordToken: string, newPassword: string) {
    return this.httpClient.put<any>(CHOOSE_NEW_PASSWORD, newPassword, {
      params: { tok: passwordToken },
    });
  }

  resetPassword(oldPassword: string, newPassword: string) {
    return this.httpClient.put<any>(RESET_PASSWORD, {
      currentPassword: oldPassword,
      newPassword,
    });
  }

  activateAccount(tok: string) {
    return this.httpClient.get<any>(ACTIVATE_ACCOUNT, { params: { tok } });
  }

  logOut() {
    try {
      // Observed a bug on a deployed environment where this call failed and prevented the rest of the method from executing
      // Add a try-catch because no matter what happens, we should not prevent logout
      this.beforeLogOut?.ngOnDestroy();
    } catch (e) {
      console.error(e);
    }
    this.httpClient.get(LOGOUT_URL, {}).subscribe(() => {
      localStorage.clear();
      this.router.navigate([PATH_LOGIN]);
    });
  }
}
