import { Component, OnDestroy, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { AlertComponent } from '../alert/alert.component';
import { LANGUAGES, LOCALSTORAGE_USER_NAME } from '../../utils/cst';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';
import { BreadCrumbService } from 'src/app/services/bread-crumb.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkspaceNode } from '../user/classes/workspace-node';
import { shortenLongString } from 'src/app/utils/utils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  currentFileType = '';
  currentFileName = '';
  currentFileId = '';
  currentProjectName = '';
  currentProjectId = '';
  destroy$: Subject<boolean> = new Subject<boolean>();

  public languageList = LANGUAGES;
  public selectedLang;

  @ViewChild('alert') alertComponent: AlertComponent;

  constructor(
    public router: Router,
    public uploadService: FileUploadService,
    public authService: AuthService,
    private translate: TranslateService,
    private langService: LangService,
    private breadCrumb: BreadCrumbService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  ngOnInit(): void {
    this.langService.current.subscribe((value) => {
      this.translate.use(value);
      if (value !== this.selectedLang) {
        this.selectedLang = value;
      }
    });
    this.breadCrumb.currentFile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentFile) => {
        this.updateActualFile(currentFile);
        if (currentFile) {
          this.breadCrumb.fileFocus$.next(currentFile.id);
          localStorage.setItem('selectedElemInTree', currentFile.id);
        }
      });
    this.breadCrumb.currentProject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentProject) => {
        this.updateActualProject(currentProject);
        if (this.currentFileName === '') {
          this.breadCrumb.currentFile$.next(null);
          this.breadCrumb.fileFocus$.next(currentProject.id);
          localStorage.setItem('selectedElemInTree', currentProject.id);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  isLogIn(): boolean {
    if (!localStorage.getItem('token')) {
      this.resetBreadCrumbValues();
    }
    return !!localStorage.getItem('token');
  }

  updateActualProject(current: { name: string; id: string }) {
    this.currentProjectName = shortenLongString(current.name, 15);
    this.currentProjectId = current.id;
  }

  updateActualFile(node: WorkspaceNode) {
    if (node) {
      this.currentFileType = node.type.toLocaleLowerCase();
      this.currentFileName = node.name;
      this.currentFileId = node.id;
      this.currentFileName = shortenLongString(this.currentFileName, 15);
    } else {
      this.currentFileName = '';
      this.currentFileType = '';
      this.currentFileId = '';
    }
  }

  // check if we are on one page
  onPage(page: string): boolean {
    // delete the parameters in the url
    const currentPage = this.router.url.split('?')[0];
    return currentPage.startsWith(page);
  }

  getUserName(): string {
    return localStorage.getItem(LOCALSTORAGE_USER_NAME);
  }

  switchLang() {
    this.langService.update(this.selectedLang, this.isLogIn());
  }

  goHome() {
    this.resetBreadCrumbValues();
    localStorage.setItem('selectedElemInTree', null);
    this.breadCrumb.type$.next(null);
  }

  goProjectRoot() {
    this.breadCrumb.currentFile$.next(null);
    localStorage.setItem('selectedElemInTree', this.currentProjectId);
    this.breadCrumb.fileFocus$.next(this.currentProjectId);
  }

  goCurrentFile() {
    localStorage.setItem('selectedElemInTree', this.currentFileId);
  }

  resetBreadCrumbValues() {
    this.currentFileType = '';
    this.currentFileName = '';
    this.currentProjectName = '';
    this.currentProjectId = '';
    this.currentFileId = '';
  }
}
