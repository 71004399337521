<app-alert #alert></app-alert>
<div
  class="new-password-container flex justify-content-center align-items-center pb-2"
>
  <!-- the new-password form -->
  <form
    class="new-password-form text-center my-5"
    [formGroup]="resetPasswordForm"
  >
    <!--  the title in the new-password form  -->
    <div class="new-password-title mb-5">
      <h1>{{ "authentification.new-password-form.title" | translate }}</h1>
    </div>

    <!--   input of the password   -->
    <div class="form-group">
      <input
        name="password"
        type="password"
        class="form-control form-control-solid h-auto"
        [ngClass]="{ 'is-invalid': password.invalid && password.dirty }"
        placeholder="{{ 'authentification.password.placeholder' | translate }}"
        formControlName="password"
      />
      <small class="form-text text-muted">{{
        "authentification.password.instruction" | translate
      }}</small>
      <!--   feedback if the password is invalid   -->
      <div
        class="invalid-feedback"
        *ngIf="password.invalid && password.errors.required"
      >
        {{ "authentification.password.feedback-empty" | translate }}
      </div>
      <div
        class="invalid-feedback"
        *ngIf="password.invalid && password.errors.minlength"
      >
        {{ "authentification.password.feedback-weak" | translate }}
      </div>
    </div>

    <!--   comfirm the password   -->
    <div class="form-group">
      <input
        name="passwordConfirm"
        type="password"
        class="form-control form-control-solid h-auto"
        [ngClass]="{
          'is-invalid':
            passwordConfirm.dirty &&
            (passwordConfirm.invalid ||
              (resetPasswordForm.hasError('mismatch') &&
                resetPasswordForm.errors.mismatch))
        }"
        placeholder="{{
          'authentification.password.confirm-placeholder' | translate
        }}"
        formControlName="passwordConfirm"
      />
      <!--   feedback if the password confirmation is incorrect   -->
      <div
        class="invalid-feedback"
        *ngIf="passwordConfirm.invalid && passwordConfirm.errors.required"
      >
        {{ "authentification.password.confirm-feedback-empty" | translate }}
      </div>
      <div
        class="invalid-feedback"
        *ngIf="
          resetPasswordForm.hasError('mismatch') &&
          resetPasswordForm.errors.mismatch
        "
      >
        {{ "authentification.password.confirm-feedback-mismatch" | translate }}
      </div>
    </div>

    <!--   auth btn   -->
    <button
      (click)="chooseNewPassword()"
      class="btn mt-3 btn-lg btn-dark-primary text-uppercase"
    >
      {{ "submit" | translate }}
    </button>
  </form>
</div>
