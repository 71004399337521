import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { PATH_LOGIN } from 'src/app/utils/cst';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertComponent } from '../../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { passwordMatchValidator } from 'src/app/utils/utils';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less'],
})
export class RegisterComponent {
  @ViewChild('alert') alertComponent: AlertComponent;

  registerForm: FormGroup;

  constructor(
    private data: AuthService,
    private dbService: DataService,
    private router: Router,
    private translate: TranslateService
  ) {
    // ---------------
    // Create the register form:
    this.registerForm = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        nom: new FormControl('', Validators.required),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        passwordConfirm: new FormControl('', Validators.required),
      },
      passwordMatchValidator
    );
  }

  get email() {
    return this.registerForm.get('email');
  }

  get nom() {
    return this.registerForm.get('nom');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get passwordConfirm() {
    return this.registerForm.get('passwordConfirm');
  }

  register() {
    if (!this.registerForm.invalid) {
      this.dbService.spinner.show();
      this.data
        .register(
          this.nom.value,
          this.email.value,
          this.password.value,
          this.translate.currentLang
        )
        .subscribe(
          (responseOK) => {
            this.dbService.spinner.hide();
            this.alertComponent.alertSuccess(
              this.translate.instant('authentification.register.request-ok'),
              3000
            );
            setTimeout(() => this.router.navigate(['PATH_LOGIN']), 2000);
          },
          (err) => {
            this.dbService.spinner.hide();
            if (typeof err.error === 'string') {
              this.alertComponent.alertDanger(err.error, 3000);
            } else {
              this.alertComponent.alertDanger(
                this.translate.instant(
                  'authentification.register.request-error'
                )
              );
            }
          }
        );
    }
  }
}
