<app-alert #alert></app-alert>
<div
  class="register-container flex justify-content-center align-items-center pb-2"
>
  <!-- the register form -->
  <form class="register-form text-center my-5" [formGroup]="registerForm">
    <!--  the title in the register form  -->
    <div class="register-title mb-5">
      <h1>{{ "authentification.register.title" | translate }}</h1>
    </div>

    <!--   input of the email   -->
    <div class="form-group">
      <input
        name="email"
        type="text"
        class="form-control form-control-solid h-auto"
        [ngClass]="{ 'is-invalid': email.invalid && email.dirty }"
        placeholder="{{ 'authentification.email.placeholder' | translate }}"
        formControlName="email"
      />
      <!--   feedback if the e-mail is invalid   -->
      <div
        class="invalid-feedback"
        *ngIf="email.invalid && email.errors.required"
      >
        {{ "authentification.email.feedback-empty" | translate }}
      </div>
      <div class="invalid-feedback" *ngIf="email.invalid && email.errors.email">
        {{ "authentification.email.feedback-wrong-format" | translate }}
      </div>
    </div>

    <!--   input of the username   -->
    <div class="form-group">
      <input
        name="username"
        type="text"
        class="form-control form-control-solid h-auto"
        [ngClass]="{ 'is-invalid': nom.invalid && nom.dirty }"
        placeholder="{{ 'name' | translate }}"
        formControlName="nom"
      />
      <!--   feedback if the username is invalid   -->
      <div class="invalid-feedback">
        {{ "authentification.register.name-feedback-empty" | translate }}
      </div>
    </div>

    <!--   input of the password   -->
    <div class="form-group">
      <input
        name="password"
        type="password"
        class="form-control form-control-solid h-auto"
        [ngClass]="{ 'is-invalid': password.invalid && password.dirty }"
        placeholder="{{ 'authentification.password.placeholder' | translate }}"
        formControlName="password"
      />
      <small class="form-text text-muted">{{
        "authentification.password.instruction" | translate
      }}</small>
      <!--   feedback if the password is invalid   -->
      <div
        class="invalid-feedback"
        *ngIf="password.invalid && password.errors.required"
      >
        {{ "authentification.password.feedback-empty" | translate }}
      </div>
      <div
        class="invalid-feedback"
        *ngIf="password.invalid && password.errors.minlength"
      >
        {{ "authentification.password.feedback-weak" | translate }}
      </div>
    </div>

    <!--   comfirm the password   -->
    <div class="form-group">
      <input
        name="passwordConfirm"
        type="password"
        class="form-control form-control-solid h-auto"
        [ngClass]="{
          'is-invalid':
            passwordConfirm.dirty &&
            (passwordConfirm.invalid ||
              (registerForm.hasError('mismatch') &&
                registerForm.errors.mismatch))
        }"
        placeholder="{{
          'authentification.password.confirm-placeholder' | translate
        }}"
        formControlName="passwordConfirm"
      />
      <!--   feedback if the password confirmation is incorrect   -->
      <div
        class="invalid-feedback"
        *ngIf="passwordConfirm.invalid && passwordConfirm.errors.required"
      >
        {{ "authentification.password.confirm-feedback-empty" | translate }}
      </div>
      <div
        class="invalid-feedback"
        *ngIf="
          registerForm.hasError('mismatch') && registerForm.errors.mismatch
        "
      >
        {{ "authentification.password.confirm-feedback-mismatch" | translate }}
      </div>
    </div>

    <!--   auth btn   -->
    <button (click)="register()" class="btn mt-3 btn-lg btn-dark-primary">
      {{ "authentification.register.button-submit" | translate }}
    </button>
  </form>
</div>
