import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { UnAuthGuard } from './utils/unauth.guard';
import { RegisterComponent } from './views/authentification/register/register.component';
import { ForgetPasswordComponent } from './views/authentification/forget-password/forget-password.component';
import { NewPasswordFormComponent } from './views/authentification/new-password-form/new-password-form.component';
import { AccountActivationComponent } from './views/authentification/account-activation/account-activation.component';
import { LoginComponent } from './views/authentification/login/login.component';
import { AuthGuard } from './utils/auth.guard';
import { Dispatcher } from './utils/dispatcher.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [Dispatcher] }, // dispatch home page
  { path: 'login', component: LoginComponent, canActivate: [UnAuthGuard] },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [UnAuthGuard],
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    canActivate: [UnAuthGuard],
  },
  {
    path: 'new-password-form/:token',
    component: NewPasswordFormComponent,
    canActivate: [UnAuthGuard],
  },
  {
    path: 'account-activation/:token',
    component: AccountActivationComponent,
    canActivate: [UnAuthGuard],
  },

  {
    path: 'editor',
    loadChildren: () =>
      import('./editor/editor.module').then((m) => m.EditorModule),
    canActivate: [AuthGuard],
  },

  // otherwise redirect to home page (depending on logged in/off status)
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
