import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AlertComponent } from '../../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.less'],
})
export class AccountActivationComponent implements OnInit {
  @ViewChild('alert') alertComponent: AlertComponent;

  message: string;
  icon: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const token: string = this.activatedRoute.snapshot.paramMap.get('token');
    this.authService.activateAccount(token).subscribe(
      (responseOK) => {
        this.message = this.translate.instant(
          'authentification.account-activation.successful'
        );
        this.icon = 'check_circle_outline';
      },
      (err) => {
        this.message = this.translate.instant(
          'authentification.account-activation.error'
        );
        this.icon = 'feedback';
      }
    );
  }
}
