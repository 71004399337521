import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver-es';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  CASCADE_DELETION,
  EXPORT_CATALOG,
  EXPORT_MODEL,
  EXPORT_ENUM,
  GEN_CONFIG_MODEL,
  GET_CATALOG_OCCURRENCES,
  GET_DOMAINS_OCCURRENCES,
  GET_ENUM_OCCURRENCES,
  GET_MODEL_OBJECT_OCCURRENCES,
  GET_FORM_OCCURRENCES,
  ROOT_URL,
  SET_OBJECT_REFERENCE,
  ElementType,
  OBJECT_TEMPLATE,
  PUBLISHED,
  PUBLISH_MODEL,
  PUBLISH_FORM,
} from '../utils/cst';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private httpClient: HttpClient,
    public spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {}

  // *****************************************************************************************************************
  // UTIL
  // *****************************************************************************************************************

  public catalogList = (id?) =>
    this.httpClient.get<any>(GET_CATALOG_OCCURRENCES, {
      params: id ? { source: id } : {},
    });

  public domainsList = (id?) =>
    this.httpClient.get<any>(GET_DOMAINS_OCCURRENCES, {
      params: id ? { source: id } : {},
    });

  public enumerationList = (id?) =>
    this.httpClient.get<any>(GET_ENUM_OCCURRENCES, {
      params: id ? { source: id } : {},
    });

  public objectList = (id?) =>
    this.httpClient.get<any>(GET_MODEL_OBJECT_OCCURRENCES, {
      params: id ? { source: id } : {},
    });

  public formList = (id?) =>
    this.httpClient.get<any>(GET_FORM_OCCURRENCES, {
      params: id ? { source: id } : {},
    });

  public details = (id: string, type: ElementType) =>
    this.httpClient.get<any>(ROOT_URL + type + '/details', {
      params: { objectId: id },
    });

  public persist = (content: any, type: ElementType) =>
    this.httpClient.post<any>(ROOT_URL + type + '/update', {
      content: content,
    });

  public cascadeDeletion = (refId: string) =>
    this.httpClient.post<any>(CASCADE_DELETION, refId);

  public generateConfigModel = (id: string) =>
    this.httpClient.post<any>(GEN_CONFIG_MODEL, { id });

  public setReferencedObject = (content: any, object: any) => {
    content.referencedObjectID = object;
    return this.httpClient.post<any>(SET_OBJECT_REFERENCE, { content });
  };

  public publishModel = (id: string) =>
    this.httpClient.post<any>(PUBLISH_MODEL(id), null);
  public publishForm = (id: string) =>
    this.httpClient.post<any>(PUBLISH_FORM(id), null);
  public getPublishedData = (id: string) => this.httpClient.get(PUBLISHED(id));

  public generate = () =>
    this.httpClient.get(ROOT_URL + 'stamp-template', { responseType: 'blob' });

  public configName = () =>
    this.httpClient.get(ROOT_URL + 'config-name', { responseType: 'text' });

  public downloadCatalog = (id, fileName) =>
    this.downloadBlob(EXPORT_CATALOG, { id }, fileName + '.xlsx');

  public downloadEnum = (id, fileName) =>
    this.downloadBlob(EXPORT_ENUM, { id }, fileName + '.xlsx');

  public downloadModel = (id, fileName) =>
    this.downloadBlob(EXPORT_MODEL, { id }, fileName + '.json');

  public exportFolder = (id) =>
    this.httpClient.get(ROOT_URL + 'export-folder', {
      params: { id },
      responseType: 'blob',
    });

  downloadBlob(url: string, params = {}, fileName) {
    this.httpClient
      .get(url, { responseType: 'blob', params })
      .subscribe((blob) => saveAs(blob, fileName));
  }
}
