import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PATH_LOGIN } from 'src/app/utils/cst';
import { AlertComponent } from '../../alert/alert.component';
import { passwordMatchValidator } from 'src/app/utils/utils';

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.less'],
})
export class NewPasswordFormComponent {
  @ViewChild('alert') alertComponent: AlertComponent;

  resetPasswordForm: FormGroup;

  constructor(
    private data: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // ---------------
    // Create the reset password form:
    this.resetPasswordForm = new FormGroup(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(6),
        ]),
        passwordConfirm: new FormControl('', Validators.required),
      },
      passwordMatchValidator
    );
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get passwordConfirm() {
    return this.resetPasswordForm.get('passwordConfirm');
  }

  chooseNewPassword() {
    if (this.resetPasswordForm.valid) {
      const newPasswordToken: string =
        this.activatedRoute.snapshot.paramMap.get('token');
      this.data
        .chooseNewPassword(newPasswordToken, this.password.value)
        .subscribe(
          (responseOK) => {
            this.alertComponent.alertSuccess(
              'authentification.new-password-form.request-ok'
            );
            setTimeout(() => this.router.navigate(['PATH_LOGIN']), 1500);
          },
          (err) => {
            this.alertComponent.alertDanger(
              'authentification.new-password-form.request-error',
              3000
            );
          }
        );
    }
  }
}
