import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CREATE_SESSION_URL, LOGOUT_URL, PATH_LOGIN } from '../utils/cst';
import { ReLoginModalComponent } from '../views/authentification/re-login-modal/re-login-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tok = localStorage.getItem('token');

    let request;
    // if user is logged in, we pass the token to the server in each request:
    if (tok) {
      const headers =
        req.url.includes('/upload') ||
        req.url.includes('/import') ||
        req.url.includes('/convert') ||
        req.url.includes('/template')
          ? new HttpHeaders({
              Authorization: 'Bearer ' + tok,
            })
          : new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + tok,
            });
      request = req.clone({ headers, withCredentials: true });
    } else {
      request = req.clone({ withCredentials: true });
    }

    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          const refreshedToken = response.headers.get('refreshToken');
          if (refreshedToken) {
            localStorage.setItem('token', refreshedToken);
          }
        }
      }),
      catchError((err) => {
        if (err.status === 403 && request.url !== CREATE_SESSION_URL) {
          this.spinner.hide();
          localStorage.removeItem('token');
          if (!ReLoginModalComponent.modalOpen && request.url !== LOGOUT_URL) {
            this.dialog.open(ReLoginModalComponent, { disableClose: true });
          }
          return EMPTY;
        } else if (err.status === 0 || err.status === 503) {
          localStorage.clear();
          this.spinner.hide();
          this.toaster.error(
            this.translate.instant('services.backend-unreachable')
          );
          this.router.navigate([PATH_LOGIN]);
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }
}
