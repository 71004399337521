import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PATH_EDITOR, PATH_LOGIN } from './cst';

@Injectable({
  providedIn: 'root',
})
export class Dispatcher {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if (localStorage.getItem('token') === null) {
      this.router.navigate([PATH_LOGIN]);
    } else {
      this.router.navigate([PATH_EDITOR]);
    }
    return false;
  }
}
