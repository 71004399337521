import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventService {
  private _ctrlDown = false;
  private _altDown = false;
  private _shiftDown = false;
  private _keyF2Pressed$: Subject<void> = new Subject<void>();

  public get ctrlDown(): boolean {
    return this._ctrlDown;
  }

  public get altDown(): boolean {
    return this._altDown;
  }

  public get shiftDown(): boolean {
    return this._shiftDown;
  }

  public get anyModifier(): boolean {
    return this._ctrlDown || this._altDown || this._shiftDown;
  }

  public get keyF2Pressed$(): Subject<void> {
    return this._keyF2Pressed$;
  }

  constructor() {}

  setCtrlDown() {
    this._ctrlDown = true;
  }

  setCtrlUp() {
    this._ctrlDown = false;
  }

  setAltDown() {
    this._altDown = true;
  }

  setAltUp() {
    this._altDown = false;
  }

  setShiftDown() {
    this._shiftDown = true;
  }

  setShiftUp() {
    this._shiftDown = false;
  }
}
