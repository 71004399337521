import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from 'src/environments/environment';
import { ModelTerm } from '../views/user/classes/model-node';

/* paths */
export const PATH_LOGIN = '/login';
export const PATH_EDITOR = '/editor';
export const PATH_CONFIGURATION = PATH_EDITOR + '/configuration';

/* server paths */
export const ROOT_URL = environment.back;

export const CATALOG_URL = ROOT_URL + 'catalog/';
export const ENUMERATION_URL = ROOT_URL + 'enumeration/';
export const DOMAINS_URL = ROOT_URL + 'domain/';
export const MODEL_URL = ROOT_URL + 'model/';
export const FORM_URL = ROOT_URL + 'form/';
export const CREATE_SESSION_URL = ROOT_URL + 'create-session';
export const REGISTER_USER_URL = ROOT_URL + 'create-account';
export const PASSWORD_FORGOTTEN = ROOT_URL + 'password-forgotten';
export const CHOOSE_NEW_PASSWORD = ROOT_URL + 'choose-new-password'; // after receiving link
export const RESET_PASSWORD = ROOT_URL + 'reset-password'; // user settings
export const ACTIVATE_ACCOUNT = ROOT_URL + 'activate-account';
export const SAVE_LANG = ROOT_URL + 'change-lang';
export const LOGOUT_URL = ROOT_URL + 'logout';
export const UPLOAD = ROOT_URL + 'upload';
export const IMPORT_MODEL = ROOT_URL + 'import-model-form';
export const IMPORT_FOLDER = ROOT_URL + 'import-folder';
export const CONVERT_XML = ROOT_URL + 'convertXML';
export const GEN_CONFIG_MODEL = ROOT_URL + 'generate-config-model';
export const INIT_CONFIG = ROOT_URL + 'init-config';
export const SET_OBJECT_REFERENCE = ROOT_URL + 'set-object-reference';
export const OBJECT_TEMPLATE = (id) => ROOT_URL + 'model/' + id + '/template';
export const PUBLISH_MODEL = (id) =>
  ROOT_URL + 'model/' + id + '/publish-model';
export const PUBLISH_FORM = (id) => ROOT_URL + 'model/' + id + '/publish-form';
export const PUBLISHED = (id) => ROOT_URL + 'model/' + id + '/published';

export const GET_CATALOG_OCCURRENCES = CATALOG_URL + 'flattened-array';
export const GET_ENUM_OCCURRENCES = ENUMERATION_URL + 'flattened-array';
export const GET_DOMAINS_OCCURRENCES = DOMAINS_URL + 'flattened-array';
export const GET_MODEL_OBJECT_OCCURRENCES = MODEL_URL + 'flattened-array';
export const GET_FORM_OCCURRENCES = FORM_URL + 'flattened-array';
export const GET_TREE = ROOT_URL + 'tree';
export const CASCADE_DELETION = ROOT_URL + 'update-reference-links';
export const EXPORT_CATALOG = ROOT_URL + 'export-catalog';
export const EXPORT_ENUM = ROOT_URL + 'export-enum';
export const EXPORT_MODEL = ROOT_URL + 'export-model-form';

/* configuration server paths */
export const CONFIGURATION_UPLOAD_MODEL = ROOT_URL + 'upload-model';
export const CONFIGURATION_DOMAINS_URL = ROOT_URL + 'domains';
export const CONFIGURATION_CONFLICT_SUGGESTIONS =
  ROOT_URL + 'conflict-suggestions';
export const CONFIGURATION_COMMIT_URL = ROOT_URL + 'commit';
export const CONFIGURATION_MULTI_COMMIT_URL = ROOT_URL + 'multi-commit';
export const CONFIGURATION_UNCOMMIT_URL = ROOT_URL + 'uncommit';
export const CONFIGURATION_CLEAR_INVALID_COMMITS =
  ROOT_URL + 'clear-invalid-commits';
export const CONFIGURATION_CLEAR_ALL_COMMITS = ROOT_URL + 'clear-all-commits';
export const CONFIGURATION_NEXT_URL = ROOT_URL + 'next-step';
export const CONFIGURATION_PREVIOUS_URL = ROOT_URL + 'previous-step';
export const CONFIGURATION_SAVE_SCENARIO = ROOT_URL + 'save-commit-scenario';
export const CONFIGURATION_APPLY_SCENARIO = ROOT_URL + 'apply-scenario';
export const CONFIGURATION_OVERWRITE_SCENARIO = ROOT_URL + 'overwrite-scenario';
export const CONFIGURATION_OVERWRITE_SCENARIO_FROM_CREATE =
  ROOT_URL + 'overwrite-scenario-from-create';
export const CONFIGURATION_DELETE_SCENARIO = ROOT_URL + 'delete-scenario';
export const CONFIGURATION_SCENARIOS = ROOT_URL + 'commit-scenarios';

/* Upload image */
export const MAX_UPLOAD_SIZE = 5;

/* element types */
export enum ElementType {
  DOMAIN = 'domain',
  ENUMERATION = 'enumeration',
  CATALOG = 'catalog',
  MODEL = 'model',
  FORM = 'form',
}

export function valueOf(e: ElementType) {
  switch (e) {
    case ElementType.ENUMERATION:
      return 0;
    case ElementType.CATALOG:
      return 1;
    case ElementType.MODEL:
      return 2;
    case ElementType.FORM:
      return 3;
  }
}

/* grid options */
export const COLUMN_MIN_WIDTH = 100;
export const DELETE_COLUMN_WIDTH = 30;
export const IMAGE_COLUMN_WIDTH = 80;

/* panels size */
export const WORKSPACE_MIN_WIDTH = 150;

/* local storage keys */
export const LOCALSTORAGE_SPLITTER_KEY = 'SPLITTER_';
export const LOCALSTORAGE_USER_MAIL = 'userEmail';
export const LOCALSTORAGE_USER_NAME = 'userName';

/* constraint and its operator list */
export const CONSTRAINT_LIST = [
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '=',
    op: 'eq',
  },
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '>',
    op: 'gt',
  },
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '≥',
    op: 'geq',
  },
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '<',
    op: 'lt',
  },
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '≤',
    op: 'leq',
  },
  {
    type: 'user.models.constraints.arithmetic',
    name: 'CCArithmetic',
    label: '≠',
    op: 'neq',
  },
  {
    type: 'user.models.constraints.global',
    name: 'CCGlobal',
    label: 'equal',
    op: 'equal',
  },
  {
    type: 'user.models.constraints.global',
    name: 'CCGlobal',
    label: 'not equal',
    op: 'different',
  },
  {
    type: 'user.models.constraints.logical',
    name: 'CCLogical',
    label: 'if',
    op: 'if',
  },
  {
    type: 'user.models.constraints.logical',
    name: 'CCLogical',
    label: 'if and only if',
    op: 'iff',
  },
  {
    type: 'user.models.constraints.logical',
    name: 'CCLogical',
    label: 'or',
    op: 'or',
  },
  {
    type: 'user.models.constraints.logical',
    name: 'CCLogical',
    label: 'exclusive or',
    op: 'xor',
  },
  {
    type: 'user.models.constraints.logical',
    name: 'CCLogical',
    label: 'and',
    op: 'and',
  },
  {
    type: 'user.models.constraints.nary-logical',
    name: 'CCNaryLogical',
    label: 'and',
    op: 'and',
  },
  {
    type: 'user.models.constraints.nary-logical',
    name: 'CCNaryLogical',
    label: 'or',
    op: 'or',
  },
  {
    type: 'user.models.constraints.member',
    name: 'CCMember',
    label: 'in',
    op: 'entre',
  },
  {
    type: 'user.models.constraints.model',
    name: 'CCModelEquality',
    label: '==',
  },
];
export const GLOBAL_OPERATOR_LIST = [
  { label: 'equal', name: 'equal' },
  { label: 'not equal', name: 'different' },
];
export const LOGICAL_OPERATOR_LIST = [
  { label: 'if', name: 'if' },
  { label: 'if and only if', name: 'iff' },
  { label: 'or', name: 'or' },
  { label: 'exclusive or', name: 'xor' },
  { label: 'and', name: 'and' },
];
export const ARITHMETIC_OPERATOR_LIST = [
  { label: '=', name: 'eq' },
  { label: '>', name: 'gt' },
  { label: '<', name: 'lt' },
  { label: '≥', name: 'geq' },
  { label: '≤', name: 'leq' },
  { label: '≠', name: 'neq' },
];

/* term and its operator list */
export const TERM_TYPE_EXPRESSION = 'user.models.constraints.types.expression';
export const TERM_TYPE_NARY = 'user.models.constraints.types.nary';
export const TERM_TYPE_PATH = 'user.models.constraints.types.path';
export const TERM_TYPE_SELECT = 'user.models.constraints.types.select.name';
export const TERM_TYPE_COLLECTION_NATIVE =
  'user.models.constraints.types.select.native';
export const TERM_TYPE_COLLECTION_SPECIFIC =
  'user.models.constraints.types.select.specific';
export const TERM_TYPE_UNARY = 'user.models.constraints.types.unary';

export const TERM_LIST = [
  { label: 'user.models.terms.constant', name: 'CCConstant' },
  { label: 'user.models.terms.ifthenelse', name: 'CCIfThenElseExpression' },
  { label: 'user.models.terms.count', name: 'CCCount', op: 'count' },

  { label: 'abs', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'abs' },
  { label: 'sqrt', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'sqrt' },
  { label: 'cos', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'cos' },
  { label: 'sin', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'sin' },
  { label: 'tan', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'tan' },
  { label: 'acos', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'acos' },
  { label: 'asin', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'asin' },
  { label: 'atan', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'atan' },
  { label: 'exp', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'exp' },
  { label: 'ln', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'ln' },
  { label: 'log2', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'log2' },
  { label: 'log10', type: TERM_TYPE_UNARY, name: 'CCUnary', op: 'log10' },

  { label: '+', type: TERM_TYPE_EXPRESSION, name: 'CCExpression', op: 'plus' },
  { label: '-', type: TERM_TYPE_EXPRESSION, name: 'CCExpression', op: 'minus' },
  { label: '*', type: TERM_TYPE_EXPRESSION, name: 'CCExpression', op: 'times' },
  {
    label: '/',
    type: TERM_TYPE_EXPRESSION,
    name: 'CCExpression',
    op: 'divide',
  },
  {
    label: '/e',
    type: TERM_TYPE_EXPRESSION,
    name: 'CCExpression',
    op: 'divide_euclidian',
  },
  {
    label: 'base_log',
    type: TERM_TYPE_EXPRESSION,
    name: 'CCExpression',
    op: 'log',
  },
  { label: '%', type: TERM_TYPE_EXPRESSION, name: 'CCExpression', op: 'mod' },
  { label: '^', type: TERM_TYPE_EXPRESSION, name: 'CCExpression', op: 'pow' },

  { label: 'min', type: TERM_TYPE_NARY, name: 'CCNary', op: 'min' },
  { label: 'max', type: TERM_TYPE_NARY, name: 'CCNary', op: 'max' },
  { label: 'geq_max', type: TERM_TYPE_NARY, name: 'CCNary', op: 'geq_max' },
  { label: 'leq_min', type: TERM_TYPE_NARY, name: 'CCNary', op: 'leq_min' },
  {
    label: 'user.models.terms.sum',
    type: TERM_TYPE_NARY,
    name: 'CCNary',
    op: 'sum',
  },
  {
    label: 'user.models.terms.product',
    type: TERM_TYPE_NARY,
    name: 'CCNary',
    op: 'product',
  },
  {
    label: 'user.models.terms.round',
    type: TERM_TYPE_UNARY,
    name: 'CCUnary',
    op: 'round',
  },
  {
    label: 'user.models.terms.ceiling',
    type: TERM_TYPE_UNARY,
    name: 'CCUnary',
    op: 'ceiling',
  },
  {
    label: 'user.models.terms.floor',
    type: TERM_TYPE_UNARY,
    name: 'CCUnary',
    op: 'floor',
  },
  {
    label: 'user.models.terms.concat',
    type: TERM_TYPE_NARY,
    name: 'CCNary',
    op: 'concat',
  },

  {
    label: 'user.models.terms.attributes',
    type: TERM_TYPE_SELECT,
    name: 'CCSelect',
  },
  {
    label: 'user.models.terms.scalar',
    type: TERM_TYPE_SELECT,
    name: 'CCScalar',
  },
] as Array<ModelTerm>;

export const SCALAR_DEFAULT_MULTIPLIER_VALUE = 0;

export const NARY_LOGICAL_OPERATOR_LIST = [
  { label: 'and', name: 'and' },
  { label: 'or', name: 'or' },
];

export const NARY_OPERATOR_LIST = [
  { label: 'Min', name: 'min' },
  { label: 'Max', name: 'max' },
  { label: 'Leq_min', name: 'leq_min' },
  { label: 'Geq_max', name: 'geq_max' },
  { label: 'user.models.terms.sum', name: 'sum' },
  { label: 'user.models.terms.product', name: 'product' },
  { label: 'concat', name: 'concat' },
];
export const EXPRESSION_OPERATOR_LIST = [
  { label: '+', name: 'plus', prio: 1, associative: 'both' },
  { label: '-', name: 'minus', prio: 1, associative: 'left' },
  { label: '×', name: 'times', prio: 2, associative: 'both' },
  { label: '/', name: 'divide', prio: 2, associative: 'left' },
  { label: '/e', name: 'divide_euclidian', prio: 2, associative: 'left' },
  { label: '%', name: 'mod', prio: 2, associative: 'left' },
  { label: '^', name: 'pow', prio: 3, associative: 'right' },
  { label: 'base_log', name: 'log', prio: 4, associative: 'left' },
];
export const UNARY_OPERATOR_LIST = [
  { label: 'user.models.terms.round', name: 'round' },
  { label: 'user.models.terms.ceiling', name: 'ceiling' },
  { label: 'user.models.terms.floor', name: 'floor' },
  { label: 'abs', name: 'abs' },
  { label: 'sqrt', name: 'sqrt' },
  { label: 'exp', name: 'exp' },
  { label: 'ln', name: 'ln' },
  { label: 'log2', name: 'log2' },
  { label: 'log10', name: 'log10' },
  { label: 'cos', name: 'cos' },
  { label: 'sin', name: 'sin' },
  { label: 'tan', name: 'tan' },
  { label: 'acos', name: 'acos' },
  { label: 'asin', name: 'asin' },
  { label: 'atan', name: 'atan' },
];

export const COLLECTION_TYPE_NATIVE_LIST = [
  {
    name: 'all',
    type: TERM_TYPE_COLLECTION_NATIVE,
    id: 'all',
    label: 'project',
  },
  {
    name: 'this',
    type: TERM_TYPE_COLLECTION_NATIVE,
    id: 'this',
    label: 'current-model',
  },
  {
    name: 'children',
    type: TERM_TYPE_COLLECTION_NATIVE,
    id: 'children',
    label: 'sub-models',
  },
];

export const OPTIONAL_TERM_PROPERTY_LIST = ['defaultMultiplier'];

/* computer arch. */
export const BYTES_PER_MB = 1048576;

/* I18N */
export const LANGUAGES = [
  { id: 'fr', label: 'Français' },
  { id: 'en', label: 'English' },
];
export const DEFAULT_LANGUAGE = 'en';

export const CONSTANT_WILDCARD = '?';

/* Reserved key words */
export const RESERVED_WORDS = ['type', CONSTANT_WILDCARD];

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('250ms', style({ opacity: 0.2 })),
  ]),
  transition(':leave', [
    style({ opacity: 0.2 }),
    animate('250ms', style({ opacity: 0 })),
  ]),
]);

// Max size of string in run view
export const MAX_STRING_SIZE = 30;

/* Custom HTML template variables */
export const INVALID_REFERENCE_TEMPLATE =
  '<span class="invalid-reference">%s</span>';
