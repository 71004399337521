<div
  class="login-container flex justify-content-center align-items-center pb-2"
>
  <!-- the login form -->
  <form class="login-form text-center my-5" [formGroup]="LoginForm">
    <!--  the title in the login form  -->
    <div class="login-title mb-5">
      <h1>{{ "authentification.login.title" | translate }}</h1>
    </div>

    <!--   input of the email   -->
    <div class="form-group">
      <input
        required
        name="email"
        type="text"
        class="form-control form-control-solid h-auto"
        [ngClass]="{
          'is-invalid':
            (email.invalid || incorrect) && (submitted || email.dirty)
        }"
        placeholder="{{ 'authentification.email.placeholder' | translate }}"
        formControlName="email"
      />
      <!--   feedback if the email is incorrect   -->
      <div
        class="invalid-feedback"
        *ngIf="email.invalid && email.errors.required"
      >
        {{ "authentification.email.feedback-empty" | translate }}
      </div>
    </div>

    <!--   input of the password   -->
    <div class="form-group">
      <input
        required
        name="password"
        type="password"
        class="form-control form-control-solid h-auto"
        [ngClass]="{
          'is-invalid':
            (password.invalid || incorrect) && (submitted || password.dirty)
        }"
        placeholder="{{ 'authentification.password.placeholder' | translate }}"
        formControlName="password"
      />
      <!--   feedback if the password is incorrect   -->
      <div
        class="invalid-feedback"
        *ngIf="password.invalid && password.errors.required"
      >
        {{ "authentification.password.feedback-empty" | translate }}
      </div>
      <div class="invalid-feedback" *ngIf="incorrect">
        {{ "authentification.login.password-feedback-wrong" | translate }}
      </div>
    </div>

    <!-- line of actions: remember password & forgot password  -->
    <div
      class="forgot-link form-group flex justify-content-between align-items-center"
    >
      <!--   check the box to remember password   -->
      <div class="form-check flex align-items-center">
        <input
          name="remember"
          type="checkbox"
          class="form-check-input my-0"
          id="remember"
          [(ngModel)]="rememberInfo"
          [ngModelOptions]="{ standalone: true } // to use ngmodel in formgroup"
        />
        <label class="form-check-label" for="remember">{{
          "authentification.login.remember-checkbox" | translate
        }}</label>
      </div>
      <!--   forgot password   -->
      <a
        [routerLink]="['/forget-password']"
        [queryParams]="{ email: email.value }"
        >{{ "authentification.login.link-forget-password" | translate }}</a
      >
    </div>

    <!--   Login btn   -->
    <button
      id="login-button"
      (click)="auth()"
      class="btn mt-3 btn-lg btn-dark-primary"
    >
      {{ "authentification.login.button-connexion" | translate }}
    </button>
  </form>
</div>
