import { Component, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertComponent } from '../../alert/alert.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.less'],
})
export class ForgetPasswordComponent {
  @ViewChild('alert') alertComponent: AlertComponent;

  forgetForm: FormGroup;

  constructor(
    private data: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.forgetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.route.queryParams.subscribe((val) => {
      if (val.email) {
        this.email.setValue(val.email);
      }
    });
  }

  get email() {
    return this.forgetForm.get('email');
  }

  sendMail() {
    if (!this.forgetForm.invalid) {
      this.data.passwordForgotten(this.email.value).subscribe(
        (responseOK) =>
          this.alertComponent.alertSuccess(
            this.translate.instant(
              'authentification.forget-password.request-ok'
            ),
            3000
          ),
        (err) => {
          this.alertComponent.alertDanger(
            this.translate.instant(
              'authentification.forget-password.request-error'
            ),
            3000
          );
        }
      );
    }
  }
}
