import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import {
  ElementType,
  LOCALSTORAGE_USER_MAIL,
  LOCALSTORAGE_USER_NAME,
  PATH_EDITOR,
} from 'src/app/utils/cst';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '../../../services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  rememberInfo: boolean;
  LoginForm: FormGroup; /* validation of the format  */
  incorrect: boolean; /* check if there is an account corresponding to the login info */
  submitted: boolean;

  constructor(
    private data: AuthService,
    private router: Router,
    private configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    // ---------------
    // Create the login form:
    this.LoginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
    // ---------------
    // Use the login infos if it has been stored:
    const userEmail = localStorage.getItem(LOCALSTORAGE_USER_MAIL);
    if (userEmail) {
      this.email.setValue(userEmail);
    }
  }

  get email() {
    return this.LoginForm.get('email');
  }

  get password() {
    return this.LoginForm.get('password');
  }

  auth() {
    this.submitted = true;
    this.incorrect = false;
    // ---------------
    // Either Spring Security with JWT or no Spring Security at all:
    if (this.LoginForm.valid) {
      this.data.login(this.email.value, this.password.value).subscribe(
        (responseOK) => {
          // clear the previous local storage except userEmail
          const userEmail = localStorage.getItem(LOCALSTORAGE_USER_MAIL);
          if (userEmail) {
            localStorage.setItem(LOCALSTORAGE_USER_MAIL, userEmail);
          }
          // store userName
          localStorage.setItem(LOCALSTORAGE_USER_NAME, responseOK.name);
          if (this.rememberInfo) {
            // ---------------
            // store the login infos if the rememberInfo box is checked:
            localStorage.setItem(LOCALSTORAGE_USER_MAIL, this.email.value);
          }
          this.router.navigate([PATH_EDITOR]);
        },
        (err) => {
          // ---------------
          // set errors :
          this.incorrect = true;
        }
      );
    }
  }
}
